import { IPostWrapper } from '@/interfaces/wrapper';
import FullImagePostCard from '../Shared/PostCard/FullImagePostCard';

const GridPhoToWrapper = ({ data }: IPostWrapper) => {
  return (
    <div className={`lg:grid lg:grid-cols-7 gap-2 flex flex-col w-full`}>
      <div className="h-[250px] lg:min-h-[306px] lg:h-[306px] w-full relative lg:col-span-4">
        <FullImagePostCard {...data?.[0]} priority />
      </div>
      <div className="lg:col-span-3 flex flex-col h-[500px] lg:h-full gap-1">
        <div className="min-h-[250px] lg:min-h-[185px]">
          <FullImagePostCard {...data?.[1]} />
        </div>
        <div className="flex h-full gap-1">
          <FullImagePostCard {...data?.[2]} />
          <FullImagePostCard {...data?.[3]} />
        </div>
      </div>
    </div>
  );
};

export default GridPhoToWrapper;
