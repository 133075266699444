import Link from 'next/link';
import Image from 'next/image';
import { IPostCardProps } from '@/interfaces/card';

const FullImagePostCard = ({
  category,
  id,
  slug,
  thumbnail,
  title,
  priority,
}: IPostCardProps) => {
  return (
    <>
      {id ? (
        <Link
          style={{
            animation: 'animate-zoomin 0.5s',
          }}
          href={`/${category?.slug}/${slug}-${id}`}
          className={`block h-full w-full relative group`}
        >
          <div className="w-full h-full relative">
            <Image
              src={thumbnail || ''}
              fill
              priority={priority}
              quality={60}
              style={{ objectFit: 'cover' }}
              sizes="(max-width: 768px) 50vw, (max-width: 1200px) 25vw, 50vw" // className="object-cover"
              alt={title ?? 'tin hay nha dat'}
            />
          </div>
          <div className="bg-gradient-to-t from-black w-full h-1/2 absolute bottom-0" />

          <div className={`absolute bottom-3 px-4`}>
            <p className="text-[10px] font-medium text-LightGray w-fit px-2 py-1 bg-primary rounded-md mb-2">
              {category?.name}
            </p>
            <h2
              className={`text-[14px] font-medium text-LightGray line-clamp-2 group-hover:opacity-70 duration-300`}
            >
              {title}
            </h2>
          </div>
        </Link>
      ) : (
        <></>
      )}
    </>
  );
};

export default FullImagePostCard;
